<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => closeShopSkinDialog('ShopSkinEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="ShopSkin"
                    ref="ShopSkinEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>皮肤图片</span>
                                </template>
                                <common-upload-component
                                    ref="upload"
                                    :limit=1
                                    :ffileList="ShopSkin.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>皮肤信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>状态</span>
                                        </template>
                                        <el-select
                                            v-model="ShopSkin.state"
                                            filterable
                                            clearable
                                            placeholder="选择状态"
                                            class="state_c"
                                        >
                                            <el-option
                                                v-for="item in AllState"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>显示名称</span>
                                        </template>
                                        <el-input v-model="ShopSkin.infant_name" placeholder="请填写皮肤显示名称"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>真实名称</span>
                                        </template>
                                        <el-input v-model="ShopSkin.name" placeholder="请填写皮肤真实名称"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>代码</span>
                                        </template>
                                        <el-input v-model="ShopSkin.code" placeholder="请填写皮肤代码"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>类型</span>
                                        </template>
                                        <el-select
                                            v-model="ShopSkin.type"
                                            filterable
                                            clearable
                                            placeholder="选择皮肤属性"
                                            class="type_c"
                                        >
                                            <el-option
                                                v-for="item in AllType"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>皮肤说明</span>
                                </template>
                                <common-editor-component
                                    ref="editor"
                                    :fcontent="ShopSkin.note"
                                ></common-editor-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>皮肤图片</span>
                                </template>
                                <img v-if="ShopSkin.picture.length" :src="ShopSkin.picture[0].url" height="200px" alt="" style="border-radius: 10px;">
                                <common-upload-component
                                    ref="upload"
                                    v-show="false"
                                    :disabled="true"
                                    :ffileList="ShopSkin.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>皮肤信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>状态</span>
                                        </template>
                                        <el-tag type="success" v-if="this.is_have_skin">已拥有</el-tag>
                                        <el-tag type="danger" v-else>未拥有</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>名称</span>
                                        </template>
                                        <span>{{ShopSkin.infant_name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>类型</span>
                                        </template>
                                        <el-tag effect="plain" type="info" v-if="ShopSkin.type ===1">卡牌</el-tag>
                                        <el-tag effect="plain" type="info" v-if="ShopSkin.type ===2">牌背</el-tag>
                                        <el-tag effect="plain" type="info" v-if="ShopSkin.type ===3">身份</el-tag>
                                        <el-tag effect="plain" type="info" v-if="ShopSkin.type ===4">角色</el-tag>
                                        <el-tag effect="plain" v-else-if="ShopSkin.type === 5">限定</el-tag>
                                        <el-tag effect="plain" type="danger" v-else-if="ShopSkin.type === 6">专属</el-tag>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>皮肤说明</span>
                                </template>
                                <div class="el-tiptap-editor__content" v-html="ShopSkin.note"></div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="!this.is_have_skin && this.UserItems['角色皮肤自选'] && (this.UserItems['角色皮肤自选'] > 0) && (this.ShopSkin.type === 4)"
                        type="primary"
                        plain
                        round
                        @click="exchange(2)"
                        >【角色皮肤自选】卡兑换（剩余{{ this.UserItems['角色皮肤自选'] }}张）</el-button
                    >
                    <el-button
                        v-else-if="!this.is_have_skin && this.UserItems['常规皮肤自选'] && (this.UserItems['常规皮肤自选'] > 0) && (this.ShopSkin.type <5)"
                        type="primary"
                        plain
                        round
                        @click="exchange(1)"
                        >【常规皮肤自选】卡兑换（剩余{{ this.UserItems['常规皮肤自选'] }}张）</el-button
                    >
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="ShopSkinDel('ShopSkinEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopSkinDialog('ShopSkinEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ShopSkinEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ShopSkinEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopSkinDialog('ShopSkinEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ShopSkinEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ShopSkinAddRequest,ShopSkinDelRequest,ShopSkinEditRequest } from '@/network/shop/ShopSkin.js'
import { ExchangeSkinRequest } from '@/network/user.js'
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'

export default {
    name: 'ShopSkinEditComponent',
    data() {
        return {
            login_loading: false,
            ShopSkin: {
                state: '',
                name: '',
                infant_name: '',
                picture: [],
                code: '',
                type: '',
                note: ''
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '皮肤详情'
            } else if (this.show_type === 1) {
                return '添加皮肤'
            } else {
                return '修改皮肤'
            }
        },
    },
    methods: {
        closeShopSkinDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitShopSkinDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopSkinInsert(formName)
            })
        },
        ShopSkinInsert(formName) {
            this.login_loading = true
            this.ShopSkin.picture = this.$refs.upload.fileList
            this.ShopSkin.note = this.$refs.editor.content
            ShopSkinAddRequest(this.ShopSkin)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitShopSkinDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            this.ShopSkin = {
                state: '',
                name: '',
                infant_name: '',
                picture: [],
                code: '',
                note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopSkinEdit(formName)
            })
        },
        ShopSkinEdit(formName) {
            this.login_loading = true
            this.ShopSkin.id = this.id
            this.ShopSkin.picture = this.$refs.upload.fileList
            this.ShopSkin.note = this.$refs.editor.content
            ShopSkinEditRequest(this.ShopSkin)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitShopSkinDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.ShopSkin.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        ShopSkinDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ShopSkinDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeShopSkinDialog(formName))
                })
                .catch((err) => {})
        },
        exchange(skin_type) {
                this.$confirm(
                '是否确定兑换？（将扣除一张对应兑换卡，且不支持7天无理由退货！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    ExchangeSkinRequest({skin_type: skin_type,skin_id: this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('兑换成功!')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.loading = false
                            this.$message.error(err)
                        })
                        .finally(() => {
                            this.$emit('RefreshItems')
                            this.$emit('details_row',this.ShopSkin.id)
                        })
                        
                        this.login_loading = false
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        shop_skin_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllState: {
            type: Array,
            default() {
                return []
            }
        },
        AllType: {
            type: Array,
            default() {
                return []
            }
        },
        UserItems: {
            type: Object|Array,
            default() {
                return {}
            }
        },
        is_have_skin: {
            type: Boolean,
            default() {
                return true
            }
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonEditorComponent
    },
    watch: {
        shop_skin_details_data(newVal) {
            this.ShopSkin = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.state_c,.type_c {
    width: 100%
}
</style>